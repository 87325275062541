<template>
  <div class="works">
    <left-menu current="Income" />
    <div class="main">
      <el-row>
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
            <el-breadcrumb-item>账单明细</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"
          ><el-link type="primary" @click="$router.back()">
            &lt;返回</el-link
          ></el-col
        >
      </el-row>
      <el-divider></el-divider>
      <div class="header-title">
        <p>累计收益{{ cashOutData.allMoney || 0 | money }}元</p>
        <div class="foot">
          <p>已入账收益{{ cashOutData.doneMoney || 0 | money }}元</p>
          <p>待确认收益{{ cashOutData.readyMoney || 0 | money }}元</p>
        </div>
      </div>
      <div class="big">
        <div class="filterBox">
          <el-select
            size="small"
            @change="selectChange"
            v-model="filterValue"
            placeholder="请选择"
          >
            <el-option label="自定义" :value="1"></el-option>
            <el-option label="近三个月" :value="3"></el-option>
            <el-option label="近六个月" :value="6"></el-option>
            <el-option label="近一年" :value="12"></el-option>
          </el-select>
          <div class="picker" v-if="filterValue === 1">
            <el-date-picker
              size="small"
              v-model="pickerValue"
              type="daterange"
              value-format="yyyy-MM-dd"
              @change="datePickerChange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="right">
          <el-select
            @change="selectchange1"
            v-model="value"
            placeholder="请选择"
          >
            <el-option label="全部账单" :value="''"></el-option>
            <el-option label="未确认" :value="0"></el-option>
            <el-option label="已确认" :value="1"></el-option>
            <el-option label="提现中" :value="2"></el-option>
            <el-option label="已提现" :value="3"></el-option>
          </el-select>
        </div>
      </div>

      <el-table
        :data="tableData"
        :cell-style="tableCell"
        :header-cell-style="headerStyle"
        style="width: 100%; margin-bottom: 48px; margin-top: 20px"
      >
        <el-table-column prop="title" label="账单名称">
        </el-table-column>
        <el-table-column
          :formatter="getOrderTypeById"
          prop="type"
          label="账单分类"
          width="150"
        >
        </el-table-column>
        <el-table-column prop="paymentCompany" label="付款方" />
        <el-table-column label="时间" width="130">
          <template slot-scope="scope">
            {{ $timestampToDate(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="money" label="金额(元)">
          <template slot-scope="scope">
            {{ scope.row.totalMoney | money }}
          </template>
        </el-table-column>
        <el-table-column prop="status" label="提现状态" width="160">
          <template slot-scope="{ row }">
            <div class="btnBox">
              <span style="display: inline-block; width: 50px">{{
                statusTxt(row.status)
              }}</span>
              <div class="warningBox">
                <!-- <el-tooltip
                  v-if="row.status == 2"
                  class="item"
                  effect="dark"
                  :content="row.desp"
                  placement="bottom"
                >
                  <img src="@/assets/warning.png" class="icon" />
                </el-tooltip> -->
                <el-link
                  v-if="row.status == 1"
                  @click="goDetail(row)"
                  style="color: #ff477e"
                  type="info"
                  >去提现</el-link
                >
                <el-link
                  v-if="row.status == 0"
                  @click="goDetail(row)"
                  style="color: #ff477e"
                  type="info"
                  >去确认</el-link
                >
                <el-link
                  v-if="row.status == 2"
                  @click="goDetail(row)"
                  style="color: #ff477e"
                  type="info"
                  >详情</el-link
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination
        v-if="total > 0"
        :current="params.pageNum"
        :total="total"
        :pageSize="params.pageSize"
        @change="onPageChange"
        @pageSizeChange="onPageSizeChange"
      />

      <bill-table filter></bill-table>
    </div>
  </div>
</template>

<script>
import LeftMenu from "@/components/LeftMenu.vue";
import { wallet_incomeList2, wallet_myBankMsg } from "../api/index";
import Pagination from "../components/Pagination.vue";
// import Title from "@/components/withdrawal/Title.vue";
import wallet from "@/api/wallet"
import BillTable from "@/views/income/components/BillTable"
const PAGESIZE = 10;
export default {
  components: { LeftMenu, Pagination, BillTable },
  data () {
    return {
      //列表数据
      tableData: [],
      //列表请求参数
      params: {
        endTime: "",
        pageNum: 1,
        pageSize: PAGESIZE,
        startTime: "",
        status: ""
      },
      //表头样式
      headerStyle: {
        background: "#F8F8F8",
        color: "#333",
        fontSize: "14px",
        fontWeight: "400",
        borderBottom: "none",
      },
      //下拉框默认展示近三个月
      filterValue: 3,
      //数据总条数
      total: 0,
      //时间段筛选的值
      pickerValue: [],
      //   累计收益
      cashOutData: {},
      value: '',
      //  银行卡信息
      bankmsg: null,
    //  单元格样式
    tableCell:{
        background: '#ffffff',
        borderDottom: '.5px solid #e8e8e8',
      }
    }
  },

  created () {
    // 获取累计收益
    wallet.detail().then(res => {
      this.cashOutData = res.cashOutData

    })
    this.params.startTime = this.formatDate(3)[0];
    this.params.endTime = this.formatDate(3)[1];
    this.getList();
    // 获取银行卡信息
    wallet_myBankMsg().then(res => { this.bankmsg = res.data })
  },
  methods: {
    //查看详情
    goDetail (val) {
      if (val.status === 1) {
        if (this.bankmsg === null) {
          this.$popup({msg:'您还未绑定银行卡，绑定后即可打款哦~',type:'warning'})
          return
        } else if (this.bankmsg.status === 0) {
          this.$popup({msg:'银行卡审核中，请等待审核通过后再进行提现操作',type:'warning'})
          return
        } else if (
          this.bankmsg.status === 2
        ) {
          this.$popup({msg:'您的银行卡审核失败，重新绑定后即可打款哦~',type:'error'})
          return
        }
      }
      this.$router.push({ path: '/billdetails', query: { id: val.id } })
    },
    //提现状态
    statusTxt (status) {
      let txt = "";
      switch (status) {
        case -1:
          txt = "已删除";
          break;
        case 0:
          txt = "待确认";
          break;
        case 1:
          txt = "已确认";
          break;
        case 2:
          txt = "提现中";
          break;
        case 3:
          txt = "已提现";
          break;
        default:
          break;
      }
      return txt;
    },

    //监听下拉款
    selectChange (e) {
      if (e !== 1) {
        this.pickerValue = [];
        this.params.startTime = this.formatDate(e)[0];
        this.params.endTime = this.formatDate(e)[1];
        this.getList();
      }
    },

    //获取收益明细信息
    getList () {
      wallet_incomeList2(this.params).then((res) => {
        let { total, list } = res.data;
        this.total = total;
        this.tableData = list;
      });
    },
    //近几月
    formatDate (month) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * month * 30);
      let startString = `${start.getFullYear()}-${start.getMonth() + 1
        }-${start.getDate()} ${start.getHours()}:${start.getMinutes()}:${start.getSeconds()}`;
      let endString = `${end.getFullYear()}-${end.getMonth() + 1
        }-${end.getDate()} ${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`;
      console.log(endString)
      return [startString, endString];
    },

    onPageChange (e) {
      this.params.pageNum = e.current;
      this.getList();
    },

    onPageSizeChange (ps) {
      this.params.pageNum = 1;
      this.params.pageSize = ps;
      this.getList();
    },

    //选择时间段
    datePickerChange (e) {
      this.params.startTime = `${e[0]} 00:00:00`;
      let end = new Date();
      this.params.endTime = `${e[1]} ${end.getHours()}:${end.getMinutes()}:${end.getSeconds()}`;
      this.getList();
    },
    // 选择类型 全部 以确定 未确定
    selectchange1 (e) {
      this.params.status = e;
      this.params.pageNum = 1,
        this.getList();
    },
    getOrderTypeById (_, __, id) {

      // 账单类型（后台字典类型10016）：1001601账单分成 1001602版税分成 1001603词曲收入 1001604其他。以后新增和编辑维护
      switch (id) {
        case '1001601': return '账单分成'
        case '1001602': return '版税分成'
        case '1001603': return '词曲收入'
        case '1001604': return '其他'
        default: return '其他'
      }
    }
  },
  filters: {
    money (val) {
      return val.toFixed(2)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/styles/works.scss";
.main {
  box-sizing: border-box;
  ::v-deep .el-divider {
    background-color: #f4f4f4;
  }
  .btnBox {
    display: flex;
    align-items: center;
    .warningBox {
      display: flex;
      align-items: center;
      margin-left: 6px;
      .icon {
        width: 14px;
        height: 14px;
        margin-right: 6px;
        cursor: pointer;
      }
    }
  }
  .header-title {
      margin-top:40px;
    p {
      margin: 0px;
    }
    > p {
      font-size: 24px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #333333;
      letter-spacing: 0px;
    }
    .foot {
      margin-top: 12px;
      display: flex;
      p {
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #666666;
        letter-spacing: 0px;
      }
      p:nth-child(2) {
        margin-left: 40px;
      }
    }
  }
  .big {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filterBox {
      display: flex;
      align-items: center;
      margin-top: 34px;
      .picker {
        margin-left: 16px;
      }

      ::v-deep .el-input__inner {
        border: 1px solid #ddd;
        padding-left: 14px;
        border-radius: 2px;
        color: #333;
      }
      ::v-deep .is-focus {
        .el-input__inner {
          border: 1px solid #ff004d;
          padding-left: 14px;
          border-radius: 2px;
          color: #333;
        }
      }
    }
    ::v-deep .right {
      margin-top: 34px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #333333;
      letter-spacing: 0px;
      .el-select {
        width: 116px;
        height: 34px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;

        .el-input__inner {
          text-align: center;
          border: 1px solid #dddddd;
        }
      }
    }
  }
}
::v-deep .el-range-separator{
    line-height:30px;
}
</style>
